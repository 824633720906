@import "@/assets/scss/style.scss";

.c-material-image-carousel {
    min-height: 300px;

    &__no-results {
        color: $light-grey;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
.ant-carousel::v-deep .slick-slide img {
    display: block;
    margin: auto;
    max-width: 75%;
    max-height: 300px;
    border: 1px solid $grey-4;
    border-radius: 4px;
}

.ant-pagination {
    text-align: center;
}
