@import "@/assets/scss/style.scss";

.c-material-search {
  width: 50%;

  &.is-new {
    width: 100%;
  }

  &__option {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 12px 5px 20px;
  }

  &__price {
    margin-left: auto;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding: 0 12px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    line-height: 32px;
  }

  &__no-results {
    min-height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
